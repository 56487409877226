import { defineStore } from 'pinia'

export const useLoaderStore = defineStore('loader', {
  state: () => ({
    isLoaded: process.env.dev || false,
    assetsToLoad: 0,
    assetsLoaded: 0
  }),
  getters: {
    getAssetsToLoad: (state) => {
      return state.assetsToLoad
    },
    getAssetsLoaded: (state) => {
      return state.assetsLoaded
    },
    getLoadingPercent: (state) => {
      return (state.assetsLoaded / state.assetsToLoad) * 100 || 0
    },
    getLoadingStatus: (state) => {
      return state.assetsLoaded === state.assetsToLoad
    },
    getIsLoaded: (state) => {
      return state.isLoaded
    }
  },
  actions: {
    addAssetsToLoad(value) {
      this.assetsToLoad += value
    },
    addAssetsLoaded() {
      this.assetsLoaded = this.assetsLoaded + 1
    },
    setIsLoaded(value) {
      this.isLoaded = value
    }
  }
})
