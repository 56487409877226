import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', {
  state: () => ({
    open: false,
    leave: false
  }),
  getters: {
    getStatus: (state) => {
      return state.open
    },
    getLeave: (state) => {
      return state.leave
    }
  },
  actions: {
    setStatus(value) {
      this.open = value
    },
    setLeave(value) {
      this.leave = value
    }
  }
})
