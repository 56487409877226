<template>
  <div>
    <header
      :class="[
        'header',
        {
          background: (!menu && headerHasBg) || shouldAlwaysHaveBg,
          menu,
          border: mainNavIndex > -1
        },
        $route.name
      ]"
    >
      <div class="header__nav">
        <div class="header__logo" :class="{ inverted: inverted }" @click="() => menu && handleClick()">
          <NuxtLink ref="logo" to="/">
            <img :src="logo" alt="Artex" />
          </NuxtLink>
        </div>
        <div class="header__content">
          <div v-show="!isMobile" class="header__content-main mainNav hideOnMobile">
            <div
              v-for="(mainItem, i) in mainNav"
              :key="i"
              class="mainNav__item"
              :class="{ hidden: i !== mainNavIndex && mainNavIndex >= 0 }"
              @mouseenter="handleMainNavMouseEnter(i)"
              @mouseleave="handleMainNavMouseLeave"
            >
              <span class="mainNav__item-label">
                {{ mainItem.title }}
              </span>

              <div
                v-if="mainItem.items && mainItem.items.length > 0"
                class="mainNav__item-sub subNav"
                :class="{ visible: i === mainNavIndex && headerIsOpen }"
              >
                <div v-for="(subItem, k) in mainItem.items" :key="k" class="subNav__item">
                  <a v-if="subItem.isExternal" :href="subItem.url" target="_blank" class="subNav__item-label">
                    {{ subItem.title }}
                  </a>
                  <NuxtLink v-else :to="subItem.url" class="subNav__item-label" hreflang="en">
                    {{ subItem.title }}
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>
          <div class="header__content-sub">
            <div
              v-for="(subItem, i) in secondNav"
              :key="i"
              class="mainNav__item"
              :class="{ hidden: mainNavIndex >= 0 }"
            >
              <a v-if="subItem.isExternal" :href="subItem.url" target="_blank" class="mainNav__item-label">
                {{ subItem.title }}
              </a>
              <NuxtLink v-else :to="subItem.url" class="mainNav__item-label" hreflang="en">
                {{ subItem.title }}
              </NuxtLink>
            </div>
          </div>

          <div
            ref="burger"
            class="burger"
            :class="{ active: menu, inverted: inverted }"
            @click="() => handleClick(true)"
          >
            <div class="burger__line line1" />
            <div class="burger__line line2" />
          </div>
        </div>
      </div>
    </header>

    <!-- mobile version -->
    <div v-if="menu" ref="menu" class="menu-container">
      <div class="menu" :class="{ 'menu-over': over !== null && over !== -1 }">
        <div class="mainNav">
          <div
            v-for="(mainItem, i) in mainNav"
            :key="i"
            class="mainNav__item"
            :class="{ hidden: i === mainNavIndex }"
            @click="handleMainNavMouseClick(i)"
          >
            <span class="mainNav__item-label">
              {{ mainItem.title }} <span class="status">{{ i === mainNavIndex ? '-' : '+' }}</span>
            </span>
            <div
              v-if="mainItem.items && mainItem.items.length > 0"
              class="mainNav__item-sub subNav"
              :class="{ visible: i === mainNavIndex && headerIsOpen }"
            >
              <div v-for="(subItem, k) in mainItem.items" :key="k" class="subNav__item">
                <a
                  v-if="subItem.isExternal"
                  :href="subItem.url"
                  target="_blank"
                  class="subNav__item-label"
                  @click="handleClick"
                >
                  {{ subItem.title }}
                </a>
                <NuxtLink v-else :to="subItem.url" class="subNav__item-label" @click="handleClick">
                  {{ subItem.title }}
                </NuxtLink>
              </div>
            </div>
          </div>
          <div v-for="(subItem, i) in secondNav" :key="i" class="mainNav__item">
            <a
              v-if="subItem.isExternal"
              :href="subItem.url"
              target="_blank"
              class="mainNav__item-label"
              @click="handleClick"
            >
              {{ subItem.title }}
            </a>
            <NuxtLink v-else :to="subItem.url" class="mainNav__item-label" @click="handleClick">
              {{ subItem.title }}
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useLayoutStore } from '~/stores/layout'
import { useHeaderStore } from '~/stores/header'
import { useMenuStore } from '~/stores/menu'
import DynamicMixin from '~/mixins/DynamicMixin'

export default {
  name: 'HeaderComponent',

  mixins: [DynamicMixin],
  data() {
    return {
      over: null,
      split: false,
      enter: null,
      leave: null,
      ready: false,
      headerIsOpen: true,
      lastScrollTop: null,
      headerHasBg: false,
      inverted: false,
      defaultOverTimeout: null,
      blackHeaderOnMobile: false,
      pagesWithNoHero: ['data-privacy'],
      menuItems: [],
      mainNavIndex: -1,
      subNav: false
    }
  },
  computed: {
    ...mapStores(useLayoutStore, useHeaderStore, useMenuStore),
    isMobile() {
      return this.layoutStore.isMobile
    },
    menu() {
      return this.menuStore.getStatus
    },
    willLeave() {
      return this.menuStore.getLeave
    },
    shouldAlwaysHaveBg() {
      if (this.isMobile) {
        return
      }

      // eslint-disable-next-line no-undef
      return this.pagesWithNoHero.includes(this.$route.name)
    },
    headerData() {
      return this.headerStore.getHeader
    },
    logo() {
      return this.headerData?.logo?.data ? this.getImagePath(this.headerData?.logo?.data?.attributes) : ''
    },
    mainNav() {
      return this.headerData?.menuMainNav
    },
    secondNav() {
      return this.headerData?.subMenuNavItems
    }
  },
  watch: {
    $route() {
      this.headerHasBg = false
      this.lastScrollTop = null
      this.createMenu()
    }
  },
  beforeMount() {
    this.createMenu()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleClick(cross = false) {
      if (this.menu) {
        const cbk = () => {
          this.menuStore.setLeave(true)

          setTimeout(() => {
            this.menuStore.setStatus(false)
          }, 150)
        }

        if (this.over && cross) {
          this.over = null
          setTimeout(() => {
            cbk()
          }, 500)
        } else {
          cbk()
        }
      } else {
        this.menuStore.setLeave(false)
        this.menuStore.setStatus(true)
      }
    },

    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop

      if (this.menu) {
        return
      }

      // HEADER ALWAYS STICKY
      this.headerHasBg = true
      this.headerIsOpen = true

      if (scrollTop === 0 && !this.shouldAlwaysHaveBg) {
        this.headerHasBg = false
      }

      // HEADER STICKY ON SCROLL TOP ONLY
      // if (scrollTop > this.lastScrollTop) {
      //   // downscroll code
      //
      //   if (this.headerIsOpen) {
      //     this.headerIsOpen = false
      //   }
      // } else {
      //   // upscroll code
      //   // eslint-disable-next-line no-lonely-if
      //   if (!this.headerIsOpen) {
      //     this.headerHasBg = true
      //     this.headerIsOpen = true
      //   }
      // }

      this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop // For Mobile or negative scrolling
    },
    handleMainNavMouseClick(index) {
      if (index !== this.mainNavIndex) {
        this.mainNavIndex = index
      } else {
        this.mainNavIndex = -1
      }
    },
    handleMainNavMouseEnter(index) {
      this.mainNavIndex = index
    },
    handleMainNavMouseLeave() {
      this.mainNavIndex = -1
    },
    createMenu() {
      this.menuItems = [
        // {
        //   content: 'Events',
        //   url: 'https://events.artex-stockexchange.com/hub-calendar',
        //   isExternal: true
        // },
        {
          content: 'Art Ipo',
          url: '/art-ipo',
          isExternal: false
        },
        {
          content: 'about',
          url: '/about-us',
          isExternal: false
        },
        {
          content: 'exchange',
          url: '/exchange',
          isExternal: false
        },
        {
          content: 'membership',
          url: '/membership',
          isExternal: false
        },
        {
          content: 'listings',
          url: '/listings',
          isExternal: false
        },
        {
          content: 'notices',
          url: '/notices',
          isExternal: false
        },
        {
          content: 'vcm',
          url: '/vcm',
          isExternal: false
        },
        {
          content: 'collectors',
          url: '/collectors',
          isExternal: false
        },
        {
          content: 'news',
          url: '/news',
          isExternal: false
        },
        {
          content: 'events',
          url: 'https://events.artex-stockexchange.com/hub-calendar',
          isExternal: true
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'Header';
</style>
