<template>
  <div id="app" :class="`${$route.path.startsWith('/equities') ? 'grayBg' : ''}`">
    <div v-if="loaderStore.isLoaded && headerStore.getHeader && footerStore.getFooter">
      <Header />
      <slot />
      <StickyButton
        v-if="footerStore.getFooter?.sticky_button"
        :link="footerStore.getFooter?.sticky_button?.url"
        :text="footerStore.getFooter?.sticky_button?.label"
      />
    </div>
    <Loader v-else />
  </div>
</template>

<script setup>
import { onBeforeMount } from 'vue'

// import { useHomeStore } from '~/stores/home'
import { useHeaderStore } from '~/stores/header'
import { useFooterStore } from '~/stores/footer'
import { useVisionStore } from '~/stores/vision'
import { useExchangeStore } from '~/stores/exchange'
import { useListingsStore } from '~/stores/listings'
import { useNewsStore } from '~/stores/news'
import { useArtIpoStore } from '~/stores/artipo'
import { useReportingStore } from '~/stores/reporting'
import { useTradingStore } from '~/stores/trading'
import { useIndividualInvestStore } from '~/stores/individual-invest'
import { useMembershipStore } from '~/stores/membership'
import { useNewListingsStore } from '~/stores/newlistings'
import { useNoticesStore } from '~/stores/notices'
import { useVcmStore } from '~/stores/vcm'
import { useComplianceStore } from '~/stores/compliance'
import { useLayoutStore } from '~/stores/layout'
import { useLoaderStore } from '~/stores/loader'
import { useGenericStore } from '~/stores/generic'

const homeStore = useHomeStore()
const headerStore = useHeaderStore()
const footerStore = useFooterStore()
const visionStore = useVisionStore()
const exchangeStore = useExchangeStore()
const listingsStore = useListingsStore()
const newsStore = useNewsStore()
const artIpoStore = useArtIpoStore()
const reportingStore = useReportingStore()
const tradingStore = useTradingStore()
const individualInvestStore = useIndividualInvestStore()
const membershipStore = useMembershipStore()
const newListingsStore = useNewListingsStore()
const noticesStore = useNoticesStore()
const vcmStore = useVcmStore()
const complianceStore = useComplianceStore()
const layoutStore = useLayoutStore()
const loaderStore = useLoaderStore()
const genericStore = useGenericStore()

await Promise.all([
  visionStore.requestVisionHomeData(),
  visionStore.requestVisionNewsEventsData(),
  visionStore.requestVisionLeadershipData(),
  exchangeStore.requestExchangeData(),
  exchangeStore.requestMembershipData(),
  exchangeStore.requestTradingData(),
  exchangeStore.requestFeesData(),
  listingsStore.requestData(),
  newsStore.requestData(),
  artIpoStore.requestData(),
  reportingStore.requestData(),
  tradingStore.requestData(),
  individualInvestStore.requestData(),
  membershipStore.requestData(),
  newListingsStore.requestData(),
  noticesStore.requestData(),
  vcmStore.requestData(),
  complianceStore.requestData(),
  homeStore.requestHomeData(),
  genericStore.requestTermsData(),
  genericStore.requestFaqData(),
  genericStore.requestCookiesData(),
  genericStore.requestLegalsData(),
  headerStore.requestData(),
  footerStore.requestData(),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Black.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Black.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Bold.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Bold.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-ExtraBold.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-ExtraBold.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Light.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Light.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Medium.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-Medium.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-UltraLight.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil-UltraLight.woff2'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil.woff'),
  import('~/assets/fonts/ButlerStencil/ButlerStencil.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Bold.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Bold.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-BoldItalic.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-BoldItalic.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Italic.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Italic.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Light.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Light.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-LightItalic.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-LightItalic.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Medium.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-Medium.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-MediumItalic.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-MediumItalic.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-SemiBold.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-SemiBold.woff2'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-SemiBoldItalic.woff'),
  import('~/assets/fonts/CormorantGaramond/CormorantGaramond-SemiBoldItalic.woff2'),
  import('~/assets/fonts/Nexa/NexaBold.woff'),
  import('~/assets/fonts/Nexa/NexaBold.woff2'),
  import('~/assets/fonts/Nexa/NexaLight.woff'),
  import('~/assets/fonts/Nexa/NexaLight.woff2'),
  import('~/assets/fonts/Nexa/NexaRegular.woff'),
  import('~/assets/fonts/Nexa/NexaRegular.woff2'),
  import('~/assets/img/contact/art.png'),
]);

const resize = () => {
  const width = window.innerWidth
  const height = window.innerHeight

  // Set isMobile
  if (width > 768) layoutStore.setIsMobile(false)
  else layoutStore.setIsMobile(true)

  // Set isPortrait
  if (width < height) layoutStore.setIsPortrait(true)
  else layoutStore.setIsPortrait(false)
}

const url = useRequestURL();
const route = useRoute();
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: 'https://' + url.host.replace('www.', '') + route.path,
    },
  ],
}))

onBeforeMount(() => {
  resize()
  window.addEventListener('resize', resize)
})
</script>

<style lang="scss">
#app {
  background-color: $black;
  min-width: 100%;

  &.grayBg {
    background: $grey16;
  }

  .menu {
    overflow: hidden;
  }

  .artIpoButton {
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    width: 7rem;
    height: 7rem;
    background: #0000ff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $white;
    font-family: $nexa-Bold;

    span {
      line-height: 0.8;
      margin-top: 0.3rem;
      font-size: 1.1rem;
    }
  }
}
</style>
