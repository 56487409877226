<template>
  <div v-if="content" class="loader">
    <div class="loader__logo">
      <img ref="logo" class="loader__logo-src" src="~assets/img/logo-blanc.svg" alt="logo" />
    </div>
    <div class="loader__content">
      <div class="loader__content-images">
        <img ref="art-1" src="~assets/img/art/art-1.png" class="artImage artImage1" alt="" />
        <img ref="art-2" src="~assets/img/art/art-2.png" class="artImage artImage2" alt="" />
        <img ref="art-3" src="~assets/img/art/art-3.png" class="artImage artImage3" alt="" />
      </div>
      <div ref="text" class="loader__content-text">
        <div
          v-for="(line, k) in getLines(content.loadingText)"
          :key="k"
          class="textLine"
          :class="{ right: k % 2 === 1 }"
        >
          <div class="textLine__inner" v-html="line" />
        </div>
      </div>
    </div>
    <div ref="back" class="loader__back">
      <div data-splitting class="loader__back-text">
        {{ content.loadingLabel }}
      </div>
      <div data-splitting class="loader__back-text second">
        {{ content.loadingLabel }}
      </div>
    </div>
    <div class="loader__indicator">
      <div class="loader__indicator-text">
        <div ref="zero" class="loader__indicator-innerText">O</div>
      </div>
      <div ref="bar" class="loader__indicator-bar">
        <div class="loader__indicator-inner" :style="`width: ${percent}%;`" />
      </div>
      <div class="loader__indicator-text">
        <div ref="percent" class="loader__indicator-innerText">{{ parseInt(percent) }} %</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapStores } from 'pinia'
import { useLoaderStore } from '~/stores/loader'
import Splitting from 'splitting'
import loadingQuery from '@/apollo/queries/components/loading.gql'

export default {
  name: 'Loader',
  apollo: {
    content: {
      prefetch: true,
      query: loadingQuery,
      update(data) {
        return data.loadingScreen.data.attributes
      },
      result() {
        this.$nextTick(() => {
          Splitting()
          this.enterEl()
        })
      }
    }
  },
  data() {
    return {
      enter: false,
      leave: false
    }
  },
  computed: {
    ...mapStores(useLoaderStore),
    status() {
      return this.loaderStore.getStatus
    },
    percent() {
      return this.loaderStore.getLoadingPercent
    }
  },
  watch: {
    status(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.loadEnd()
      }
    },
    enter(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.loadEnd()
      }
    }
  },
  mounted() {

  },
  methods: {
    getLines(str) {
      return str.replace('[blue]', '<span class="blue">&nbsp;').replace('[/blue]', '&nbsp;</span>').split('\n')
    },
    enterEl() {
      this.enter = true
    },
    leaveEl() {
      this.loaderStore.setIsLoaded(true)
    },
    loadEnd() {
      if (!this.leave && this.enter) {
        this.leave = true
        this.leaveEl()
      }
    }
  }
}
</script>

<style lang="scss">
@import 'Loader';
</style>
