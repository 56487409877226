import { defineStore } from 'pinia'
import headerQuery from '../apollo/queries/components/header.gql'

export const useHeaderStore = defineStore('header', {
  state: () => ({
    data: {}
  }),
  getters: {
    getHeader: (state) => {
      return state.data.headers?.data[0]?.attributes
    }
  },
  actions: {
    async requestData() {
      if (Object.keys(this.data).length !== 0) return

      const { data } = await useAsyncQuery(headerQuery) // eslint-disable-line no-undef

      if (data) this.data = data
    }
  }
})
